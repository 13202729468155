
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup () {
    const date = ref('')
    const daterange = ref('')

    return {
      date,
      daterange
    }
  }
})
